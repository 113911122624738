import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getInstallmentsByContractId } from "../../../actions/accountsactions/getInstallmentsByContractId";

export const InstallResceduleform = (props) => {
  console.log("props?.ContractId", props?.contractId);
  const resultresceduledata = useSelector(
    (state) => state.getRescheduleInstallmentRed
  );
  console.log("resultresceduledata", resultresceduledata);
  let dispatch = useDispatch();
  const [InstallmentDurationList, setInstallmentDurationList] = useState([]);
  const [idval, setidval] = useState(0);
  const [NetTotalAmount, setNetTotalAmount] = useState(0);
  const [tab, settab] = useState("Custom");
  const [installmenttotal, setinstallmenttotal] = useState("");
  const [render, setrender] = useState(false);
  const [autoAmt, setautoAmt] = useState(false);
  useEffect(() => {
    dispatch(getInstallmentsByContractId(props?.contractId));
  }, [dispatch, props]);
  const [autoInstallmentData, setautoInstallmentData] = useState({
    startDate: "",
    amount: "",
    noOfInstallments: "",
    interval: 1,
    dayOfMonth: 1,
    bubbleInterval: "",
    bubbleInstallment: false,
    bubbleAmt: "",
  });

  let installmentsum = 0;
  if (resultresceduledata?.data?.ContractInstallments !== undefined) {
    resultresceduledata?.data?.ContractInstallments.map((val) => {
      installmentsum +=
        parseFloat(val.DueAmount) - parseFloat(val.ReceivedAmount);
    });
  }

  function savedata() {
    let totalAmt = 0;
    InstallmentDurationList.forEach((itm) => {
      totalAmt = totalAmt + itm.amount;
    });
    if (totalAmt === installmentsum) {
      var installmentList = [];
      InstallmentDurationList.map((x) => {
        installmentList.push({
          ContractId: resultresceduledata.data.ContractId,
          DueAmount: x.amount,
          DueDate: x.date,
          InstallmentNo: x.id,
          InstallmentStatus: "Pending",
        });
      });
      document.getElementById("instasavebtn").disabled = true;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Contracts/RescheduleInstallments/Add`,
        data: {
          Installments: installmentList,
          ContractId: resultresceduledata.data.ContractId,
          ProjectId: parseInt(`${localStorage.getItem("ProjectId")}`),
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result.data.IsSuccess === true) {
            toast.success(result.data.Message);

            setInstallmentDurationList([]);

            document.getElementById("instasavebtn").disabled = false;
            if (document.getElementById("autoinstallbutton") !== null)
              document.getElementById("autoinstallbutton").disabled = "false";
            props.cancelcontracttable();
          } else {
            toast.error(result.data.Message);
            document.getElementById("instasavebtn").disabled = false;
          }
        },
        (error) => {
          toast.error(error);
          document.getElementById("instasavebtn").disabled = false;
        }
      );
    } else {
      toast.info("Generate Installments");
    }
  }
  // custom installments
  const custominstallments = () => {
    if (parseInt(document.getElementById("remainingamt").value) == 0) {
      toast.error("Remaining Amount is 0 , you can't create installments");
    } else if (document.getElementById("customDate").value === "") {
      toast.error("Select Date");
    } else if (document.getElementById("customAmount").value === "") {
      toast.error("Enter custome installment amount");
    } else {
      if (
        parseFloat(
          document.getElementById("customAmount").value.replace(/,/g, "")
        ) > 0
      ) {
        let sum = 0;
        InstallmentDurationList.map((x) => {
          if (x.amount != "" || x.amount != null || x.amount != undefined)
            sum = sum + parseFloat(x.amount);
        });

        let idv = idval + 1;
        let amount = parseFloat(
          document.getElementById("customAmount").value.replace(/,/g, "")
        );
        let installmentdate = document.getElementById("customDate").value;
        if (amount === "") {
          amount = "0";
        }
        sum = sum + parseFloat(amount);
        if (
          parseFloat(
            document.getElementById("customAmount").value.replace(/,/g, "")
          ) >
          parseFloat(
            document.getElementById("remainingamt").value.replace(/,/g, "")
          )
        ) {
          toast.error("Installments value is greater than remaining amount");
        } else if (sum === 0) {
          toast.error("Please enter value greater than 0!");
        } else {
          // document.getElementById("advanceval").disabled = true;
          let item = {
            id: idv,
            amount: amount,

            date: installmentdate,
          };
          setidval(idv + 1);
          setInstallmentDurationList([...InstallmentDurationList, item]);
          // document.getElementById("NoOfInstallements").value = (
          //     InstallmentDurationList.length + 1
          // ).toString();
          document.getElementById("customAmount").value = "";
        }
      } else {
        toast.error("Please enter value greater than 0!");
      }
    }
  };
  const deleteinstallment = (idx) => {
    const rows = [...InstallmentDurationList];
    rows.splice(idx, 1);

    setInstallmentDurationList(rows);
  };
  // custom installments end
  // auto installments
  function monthDiff(dateFrom, dateTo) {
    return (
      1 +
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  }
  function generateInstallments(
    startDate,
    dayOfMonth,
    installmentAmount,
    numberOfInstallments,
    intervalMonths,
    totalAmount,
    extraInstallmentAmount,
    extraInstallmentInterval
  ) {
    // Initialize variables
    let installments = [];
    let currentDate = new Date(startDate);
    let currentDayOfMonth = currentDate.getDate();

    // If the current day is after the specified day of the month, move to the next month
    if (currentDayOfMonth > dayOfMonth) {
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    // Set the day of the month for the first installment
    currentDate.setDate(dayOfMonth);

    // Generate regular installments
    let j = 1;
    let k = extraInstallmentInterval;
    let totalAmt = 0;
    for (let i = 0; i <= numberOfInstallments - 1; i++) {
      // Create a new installment object with date and amount
      let installment = null;
      if (i + 1 === k) {
        installments.push({
          id: j,
          // date: moment(
          //   moment(startDate).add("M", Number(k)).format("YYYY-MM-DD")
          // ).format("DD-MMM-YYYY"),
          date: moment(currentDate).format("DD-MMM-YYYY"),
          amount: Number(extraInstallmentAmount),
        });
        j = j + 1;
        k = k + Number(extraInstallmentInterval);
        totalAmt = totalAmt + Number(extraInstallmentAmount);
      }
      if (i + 1 === Number(numberOfInstallments)) {
        if (totalAmount !== totalAmt) {
          installment = {
            id: j,
            date: moment(currentDate).format("DD-MMM-YYYY"),
            amount: Math.abs(Number(totalAmount) - totalAmt),
          };
          totalAmt = totalAmt + Number(installmentAmount);
          j = j + 1;
          installments.push(installment);
        }
      } else {
        let existingInstallment = installments.find(
          (installment) =>
            installment.date === moment(currentDate).format("DD-MMM-YYYY")
        );
        if (!existingInstallment) {
          installment = {
            id: j,
            date: moment(currentDate).format("DD-MMM-YYYY"),
            amount: Number(installmentAmount),
          };
          totalAmt = totalAmt + Number(installmentAmount);
          j = j + 1;
          installments.push(installment);
        }
      }

      // Move to the next month with the specified interval
      currentDate.setMonth(currentDate.getMonth() + intervalMonths);
    }
    // Generate extra installments
    console.log("installments", installments);
    return installments;
  }

  const autoinstallments = () => {
    let amount = 0;
    if (autoAmt && autoInstallmentData.bubbleInstallment) {
      amount =
        (installmentsum -
          autoInstallmentData.bubbleAmt *
            (autoInstallmentData.noOfInstallments /
              autoInstallmentData.bubbleInterval)) /
        (autoInstallmentData.noOfInstallments -
          autoInstallmentData.noOfInstallments /
            autoInstallmentData.bubbleInterval);
      setautoInstallmentData({
        ...autoInstallmentData,
        amount: Math.trunc(amount),
      });
    } else {
      amount = autoInstallmentData.amount;
    }
    if (autoInstallmentData.startDate === "") {
      toast.error("Please select start date");
    } else if (autoInstallmentData.amount === "") {
      toast.error("Please enter installment amount");
    } else if (autoInstallmentData.noOfInstallments === "") {
      toast.error("Please enter number of installments");
    } else {
      if (autoInstallmentData.bubbleInstallment) {
        if (autoInstallmentData.bubbleAmt == "") {
          toast.error("Please enter amount of installment amount");
          return;
        }
        if (autoInstallmentData.bubbleInterval == "") {
          toast.error("Please enter bubble installment interval");
          return;
        }
      }

      setInstallmentDurationList([]);
      const installments = generateInstallments(
        new Date(`${autoInstallmentData.startDate}-01`),
        Number(autoInstallmentData.dayOfMonth),
        // autoInstallmentData.amount,
        Math.trunc(amount),
        autoInstallmentData.noOfInstallments,
        Number(autoInstallmentData.interval),
        installmentsum,

        autoInstallmentData.bubbleInstallment
          ? autoInstallmentData.bubbleAmt
          : 0,
        autoInstallmentData.bubbleInstallment
          ? Number(autoInstallmentData.bubbleInterval)
          : 0
      );
      console.log("totalAmount", installmentsum);

      console.log(installments);
      if (installments.length > autoInstallmentData.noOfInstallments && autoInstallmentData.bubbleInstallment) {
        // Remove the last installment
        const lastInstallment = installments.pop();
    
        // Add the amount of the last installment to the second-to-last installment
        if (installments.length > 0) {
            installments[installments.length - 1].amount += lastInstallment.amount;
        }
    }
      let amt = 0;
      installments.forEach((itm) => {
        amt = amt + itm.amount;
      });

      if (amt !== installmentsum) {
        toast.warning(`Installments amount must be equal to ${installmentsum}`);
        return;
      }
      setInstallmentDurationList(installments);
      // let startmonth = document.getElementById("startDate").value;
      // let endmonth = document.getElementById("Enddate").value;
      // let noofinstall = parseInt(document.getElementById("noofinstall").value);
      // let paydate = document.getElementById("paydate").value;

      // if (startmonth !== "" && endmonth !== "" && noofinstall !== "") {
      //   document.getElementById("autoinstallbutton").disabled = "true";

      //   let totalmonths = monthDiff(new Date(startmonth), new Date(endmonth));
      //   let totalintallmentsmonths = totalmonths / noofinstall;
      //   let singleintallvalue = Math.round(
      //     parseFloat(
      //       document.getElementById("remainingamt").value.replace(/,/g, "")
      //     ) / noofinstall
      //   );
      //   let tabledata = [];
      //   let dt = new Date(
      //     parseInt(startmonth.split("-")[0]),
      //     parseInt(startmonth.split("-")[1]) - 1,
      //     paydate
      //   );
      //   let tIV = 0;
      //   let i = 1;
      //   for (; i <= noofinstall - 1; i++) {
      //     // let idv = idval;
      //     tIV += singleintallvalue;

      //     let item = {
      //       id: i,
      //       amount: parseFloat(singleintallvalue),
      //       date: moment(dt).format("DD-MMM-YYYY"),
      //     };
      //     dt.setMonth(dt.getMonth() + parseInt(totalintallmentsmonths));
      //     setidval(i);
      //     tabledata.push(item);
      //     // setidval(idv + 1);
      //   }
      //   // dt.setMonth(dt.getMonth() + parseInt(totalintallmentsmonths));
      //   let item = {
      //     id: i,
      //     amount: parseFloat(
      //       document.getElementById("remainingamt").value.replace(/,/g, "") -
      //         tIV
      //     ),
      //     date: moment(dt).format("DD-MMM-YYYY"),
      //   };

      //   tabledata.push(item);
      //   setInstallmentDurationList(tabledata);
      //   // setInstallmentDurationList([...InstallmentDurationList, item]);
      // } else {
      //   toast.error("select start, end month and number of installments");
      // }
    }
  };
  // auto installments end

  // useEffect(() => {

  //     dispatch(installrescedule());

  // }, [dispatch]
  // )
  useEffect(() => {
    let total = 0;
    if (InstallmentDurationList.length !== 0) {
      InstallmentDurationList.forEach((x) => {
        total += parseFloat(x.amount);
      });
    }

    setinstallmenttotal(parseFloat(total).toLocaleString());
  }, [InstallmentDurationList]);
  useEffect(() => {
    if (
      document.getElementById("remainingamt") &&
      document.getElementById("remainingamt").value !== 0
    ) {
      document.getElementById("remainingamt").value = parseFloat(
        parseFloat(
          document.getElementById("remainingamt").value.replace(/,/g, "")
        ) - parseFloat(installmenttotal.replace(/,/g, ""))
      ).toLocaleString();

      // document.getElementById("remainingamt").value = NetTotalAmount - installmenttotal;
    }
  }, [installmenttotal, NetTotalAmount]);
  useEffect(() => {
    if (InstallmentDurationList.length <= 0) {
      if (document.getElementById("autoinstallbutton")) {
        document.getElementById("autoinstallbutton").disabled = false;
      }
    }
  }, [InstallmentDurationList]);
  useEffect(() => {
    if (autoAmt && autoInstallmentData.noOfInstallments) {
      setautoInstallmentData({
        ...autoInstallmentData,
        amount: Math.trunc(
          installmentsum / autoInstallmentData.noOfInstallments
        ),
      });
      setrender(!render);
    }
  }, [autoInstallmentData.noOfInstallments, autoAmt]);
  return (
    <>
      <div className="row">
        <div className="col-md-2 mt-3 text-left p-0">
          <label className="input_label m-0 ">Unit Name:</label>
        </div>
        <div className="col-md-3 mt-3  p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="unit"
            readOnly
            defaultValue={
              resultresceduledata?.data?.Unit !== undefined
                ? resultresceduledata?.data?.Unit?.UnitName
                : null
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="table-responsive mt-4">
          <table className="table table-borderless m-0 table-hover cur_sor">
            <thead>
              <tr>
                <th>Installment No</th>
                <th>Installment Id</th>
                <th>Due Date</th>
                <th>Due Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {resultresceduledata?.data?.ContractInstallments !== undefined
                ? resultresceduledata?.data?.ContractInstallments.map(
                    (val, index) => {
                      let year = val.DueDate.slice(0, 4);
                      let day = val.DueDate.slice(8, 10);
                      let month = val.DueDate.slice(5, 7);
                      switch (month) {
                        case "01":
                          month = "Jan";

                          break;
                        case "02":
                          month = "Feb";

                          break;
                        case "03":
                          month = "Mar";

                          break;
                        case "04":
                          month = "Apr";

                          break;
                        case "05":
                          month = "May";

                          break;
                        case "06":
                          month = "Jun";

                          break;
                        case "07":
                          month = "Jul";

                          break;
                        case "08":
                          month = "Aug";

                          break;
                        case "09":
                          month = "Sep";

                          break;
                        case "10":
                          month = "Oct";
                          break;
                        case "11":
                          month = "Nov";
                          break;
                        case "12":
                          month = "Dec";
                          break;
                        default:
                          break;
                      }
                      return (
                        <tr key={index}>
                          <td>{val.InstallmentNo}</td>
                          <td>{val.ContractInstallmentId}</td>
                          <td>{day + "-" + month + "-" + year}</td>
                          <td>
                            {parseFloat(
                              parseFloat(val.DueAmount) -
                                parseFloat(val.ReceivedAmount)
                            ).toLocaleString()}
                          </td>

                          <td>{val.InstallmentStatus}</td>
                        </tr>
                      );
                    }
                  )
                : null}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 text-center mt-2  p-0 d-flex">
          <button
            className={
              tab === "Custom" ? "adjust_btns_selected  " : "adjust_btns  "
            }
            onClick={() => {
              settab("Custom");
            }}
          >
            <span className="comp_btns_text">Custom Installments</span>
          </button>

          <button
            className={
              tab === "Auto"
                ? "adjust_btns_selected ml-1 "
                : "adjust_btns ml-1 "
            }
            onClick={() => {
              settab("Auto");
            }}
          >
            <span className="comp_btns_text">Auto Installments</span>
          </button>
        </div>
        {tab === "Custom" && (
          <div className="col-md-12 mt-3 p-0">
            <div className="row ">
              <div className="col-md-12   ">
                <label className="input_label m-0 ">
                  <b>Custom Installments:</b>
                </label>
              </div>
              <div className="col-md-2 mt-3  p-0">
                <label className="input_label m-0 ">Installment Amount</label>
              </div>
              <div className="col-md-4 mt-3  p-0">
                <input
                  type="text"
                  className="input_styling "
                  id="customAmount"
                  placeholder="amount"
                  onChange={(e) => {
                    if (e.target.value.length > 0)
                      document.getElementById("customAmount").value =
                        parseFloat(
                          e.target.value.replace(/,/g, "")
                        ).toLocaleString();
                  }}
                />
              </div>
              <div className="col-md-2 mt-3  p-0">
                <label className="input_label m-0 ">Installment Date</label>
              </div>
              <div className="col-md-4 mt-3  p-0">
                <input
                  type="date"
                  className="input_date_styling "
                  id="customDate"
                />
              </div>
              <div className="col-md-6 mt-3 text-center p-0"></div>
              <div className="col-md-2 mt-3 text-center p-0">
                <label className="input_label m-0 ">Remaining Amt:</label>
              </div>
              <div className="col-md-4 mt-3  p-0">
                <input
                  type="text"
                  className="input_styling "
                  id="remainingamt"
                  value={installmentsum}
                  // onChange={(e) => { if (e.target.value.length > 0) document.getElementById("remainingamt").value = parseFloat(e.target.value.replace(/,/g, '')).toLocaleString() }}
                />
              </div>
              <div
                className="col-md-12 mt-3 text-right
                       pr-4"
              >
                <button
                  className="save_btn "
                  onClick={() => {
                    custominstallments();
                  }}
                  id="custominstallbutton"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        )}
        {tab === "Auto" && (
          <div className="col-md-12 mt-3  p-0">
            <div className="row ">
              <div className="col-md-12   ">
                <label className="input_label m-0 ">
                  <b>Auto Installments:</b>
                </label>
              </div>
              <div className="col-md-2 mt-3  p-0">
                <label className="input_label m-0 ">Start:</label>
              </div>
              <div className="col-md-4 mt-3  p-0">
                <input
                  type="month"
                  value={autoInstallmentData.startDate}
                  onChange={(e) => {
                    setautoInstallmentData({
                      ...autoInstallmentData,
                      startDate: e.target.value,
                    });
                  }}
                  className="input_date_styling "
                  id="startDate"
                />
              </div>
              <div className="col-md-2 mt-3  p-0">
                <label className="input_label m-0 ">Install. Amt:</label>
              </div>
              <div className="col-md-2 mt-3  p-0">
                <input
                  disabled={autoAmt}
                  type="number"
                  value={autoInstallmentData.amount}
                  onChange={(e) => {
                    setautoInstallmentData({
                      ...autoInstallmentData,
                      amount: e.target.value,
                    });
                  }}
                  className="input_styling"
                  placeholder="Amount"
                />
              </div>
              <div className="col-md-2 mt-3  p-0 d-flex" style={{ gap: 2 }}>
                <input
                  className="mb-3"
                  type="checkbox"
                  checked={autoAmt}
                  onChange={(e) => {
                    setautoAmt(e.target.checked);
                  }}
                />
                <p>Auto</p>
              </div>
              <div className="col-md-2 mt-3  p-0">
                <label className="input_label m-0 ">Pay. Interval:</label>
              </div>
              <div className="col-md-4 mt-3  p-0">
                <input
                  type="number"
                  value={autoInstallmentData.interval}
                  onChange={(e) => {
                    setautoInstallmentData({
                      ...autoInstallmentData,
                      interval: e.target.value,
                    });
                  }}
                  className="input_styling"
                  placeholder="In Months"
                />
              </div>
              <div className="col-md-2 mt-3  p-0">
                <label className="input_label m-0 ">Pay. Date</label>
              </div>
              <div className="col-md-2 mt-3  p-0">
                <select
                  className="input_styling"
                  value={autoInstallmentData.dayOfMonth}
                  onChange={(e) => {
                    setautoInstallmentData({
                      ...autoInstallmentData,
                      dayOfMonth: e.target.value,
                    });
                  }}
                >
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                  <option>13</option>
                  <option>14</option>
                  <option>15</option>
                  <option>16</option>
                  <option>17</option>
                  <option>18</option>
                  <option>19</option>
                  <option>20</option>
                  <option>21</option>
                  <option>22</option>
                  <option>23</option>
                  <option>24</option>
                  <option>25</option>
                  <option>26</option>
                  <option>27</option>
                  <option>28</option>
                </select>
              </div>
              <div className="col-md-2 mt-3  p-0"></div>
              <div className="col-md-2 mt-3  p-0">
                <label className="input_label m-0 ">No. of Install.</label>
              </div>
              <div className="col-md-4 mt-3 p-0">
                <input
                  type="number"
                  className="input_styling "
                  value={autoInstallmentData.noOfInstallments}
                  onChange={(e) => {
                    setautoInstallmentData({
                      ...autoInstallmentData,
                      noOfInstallments: e.target.value,
                    });
                  }}
                  placeholder="No of Installments"
                />
              </div>{" "}
              <div className="col-md-2 mt-3  p-0">
                <label className="input_label m-0 ">Bubble Inst.</label>
              </div>
              <div className="col-md-4 mt-3  p-0">
                <input
                  type="checkbox"
                  checked={autoInstallmentData.bubbleInstallment}
                  onChange={(e) =>
                    setautoInstallmentData({
                      ...autoInstallmentData,
                      bubbleInstallment: e.target.checked,
                    })
                  }
                />
              </div>
              {autoInstallmentData.bubbleInstallment && (
                <>
                  <div className="col-md-2 mt-3  p-0">
                    <label className="input_label m-0 ">Bubble Interval.</label>
                  </div>
                  <div className="col-md-4 mt-3 p-0">
                    <input
                      type="number"
                      className="input_styling "
                      value={autoInstallmentData.bubbleInterval}
                      onChange={(e) => {
                        setautoInstallmentData({
                          ...autoInstallmentData,
                          bubbleInterval: e.target.value,
                        });
                      }}
                      placeholder="Bubble Installment Interval"
                    />
                  </div>{" "}
                  <div className="col-md-2 mt-3  p-0">
                    <label className="input_label m-0 ">Bubble Amt.</label>
                  </div>
                  <div className="col-md-4 mt-3 p-0">
                    <input
                      type="number"
                      className="input_styling "
                      value={autoInstallmentData.bubbleAmt}
                      onChange={(e) => {
                        setautoInstallmentData({
                          ...autoInstallmentData,
                          bubbleAmt: e.target.value,
                        });
                      }}
                      placeholder="Bubble Installment Amount"
                    />
                  </div>{" "}
                </>
              )}
              <div className="col-md-12 mt-3 text-right ">
                <button
                  className="save_btn ml-2 "
                  onClick={() => {
                    autoinstallments();
                  }}
                  id="autoinstallbutton"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row pr-md-2 pt-md-4">
        <div className=" col-6 pr-0 pl-0  "></div>
        <div className=" col-6 pr-0 pl-0  text-right ">
          <span>
            <button
              className="cancel_btn ml-2  "
              onClick={props.cancelcontracttable}
            >
              Cancel
            </button>
          </span>

          <span>
            <button
              className="save_btn ml-2  "
              id="instasavebtn"
              onClick={savedata}
            >
              <BsCloudUpload size="1.3em" />
              Save
            </button>
          </span>
        </div>
      </div>

      <div className="  mt-3 overflow-auto" style={{ height: "145px" }}>
        <table class="table tableCash table-hover ">
          <thead class="purple whiteText">
            <tr>
              <th scope="col">Installment No</th>
              <th scope="col">Amount</th>
              <th scope="col">Date</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {InstallmentDurationList.map((x, idx) => (
              <tr>
                <th scope="row">{x.id}</th>
                <td>{x.amount.toLocaleString()}</td>
                <td>{x.date}</td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteinstallment(idx)}
                >
                  <MdDeleteForever size="1.6em" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="row mt-1">
        <div className="col-6 text-center">
          <b>Total:</b> {installmenttotal}
        </div>
        {/* <div className="col-6 text-center"><b>Difference:</b> {installmentdifference}</div> */}
      </div>
    </>
  );
};
